.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 144px;
  }

.item p {
    text-align: center;
    color: var(--black-100);
    margin: 4px;
    padding: 4px;
    line-height: 17.07px;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 14px;
    height: 32px;
    width: 96px;
    background-color: var(--white-200);
}

.img1, .img2 {
    background-image: linear-gradient(to right, #FFBC28, #F23A3A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.img1 {
    height: 25px;
    width: 25px;
}

.img2 {
    height: 46px;
    width: 46px;
}

@media (min-width: 478px) {
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 144px;
        width: 250px;
      }
    
    .item p {
        text-align: center;
        color: var(--black-100);
        margin: 4px;
        padding: 4px;
        line-height: 17.07px;
        letter-spacing: -0.02em;
        font-weight: 400;
        font-size: 18px;
        height: 32px;
        width: 120px;
        background-color: var(--white-200);
    }
    
    .img1, .img2 {
        background-image: linear-gradient(to right, #FFBC28, #F23A3A);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .img1 {
        height: 40px;
        width: 40px;
    }
    
    .img2 {
        height: 60px;
        width: 60px;
  }
}
