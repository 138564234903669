.container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
    background-color: var(--white-200);
}

.containerOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.texts {
    text-align: center;
}

.texts *:first-child{
  margin-top: 16px;
}

.texts *:nth-child(2) {
  margin: 16px 0px;
}

.texts *:nth-child(3) {
  margin: 16px 0px;
}

.texts *:last-child{
  margin-bottom: 16px;
}

.div_middle {
    background-color: var(--white-200);
    width: 100%;
    flex-grow: 1;    
}


@media (min-width: 430px) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: auto;
    background-color: var(--white-200);

}

.containerOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.texts {
    text-align: center;
}

.texts *:first-child{
  margin-top: 16px;
}

.texts *:nth-child(2) {
  margin: 16px 0px;
}

.texts *:nth-child(3) {
  margin: 16px 0px;
}

.texts *:last-child{
  margin-bottom: 16px;
}

.div_middle {
    background-color: var(--white-200);
    width: 100%;
    flex-grow: 1;    
}
  }

