.select {
    /* display: flex; */
    /* flex-direction: row;
    align-items: center;
    justify-content: center; */
    /* padding: 16px; */
    border-radius: 8px;
    width: 100%;
    background-color: var(--white-200);
    margin-top: 16px;
}

.unselectedOption, .selectedOption, .selectedComment, .unselectedComment {
    padding: 15px 8px 15px 12px;
    margin-bottom: 12px;
    background-color: var(--white-100);
    border-radius: 8px;
    align-items: center;
}

.selectedOption {
    background: var(--black-100);
}

.selectedOption h3, 
.selectedOption svg {
    color: var(--white-100);
}

.selectedOption h3, 
.selectedOption svg,
.unselectedOption h3, 
.unselectedOption svg,
.selectedComment h3, 
.selectedComment h5, 
.selectedComment svg,
.unselectedComment h3, 
.unselectedComment h5, 
.unselectedComment svg {
    padding-left: 12px;
}

/* Com comentário */

.selectedComment {
    background-color: var(--black-100);
}

.unselectedComment {
    background-color: var(--white-100);
}

.selectedComment h3, 
.selectedComment h5, 
.selectedComment svg {
    color: var(--white-100);
}

.select h5 {
    margin-top: 10px
}

.select h5 ul {
    padding-left: 20px;
}


