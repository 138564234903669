.count {
    display: flex;
    padding: 32px 24px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    background-color: var(--white-100);
    margin-top: 16px;
}

.count button, .count p {
    background-color: var(--white-100);
    border-radius: 8px;
    border: none;
    color: var(--black-100);
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    width: 28px;
    height: 44px;
}

.count p {
    margin: 0px 100px;
}