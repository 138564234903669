.container {
    text-align: center;
    padding-bottom: 24px;
    width: 100%;
}

.container h2 {
    margin-top: 16px;
}

.container h5 {
    margin: 16px 0px;
}

.container h4 {
    padding-top: 16px
}

.container label {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--white-400);
}

.data {
    margin-top: 16px;
}

.field {
    width: 90%;
}