.box {
  width: 100%;
}

.radioGroup {
  margin-top: 16px;
  width: 100%;
}

.div_option {
    border-radius: 8px;
    padding: 32px 12px;
}

.div_option h5 {
  padding-top: 16px;
}

.radio {
  width: 100%;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.radio svg {
  color: var(--white-100);
}

.selectedOption {
  background: var(--black-100);
  border-radius: 8px;
  margin: 8px 0px;
}

.selectedOption svg {
  padding-left: 12px;
}

.selectedOption h3,
.selectedOption h5 {
  color: var(--white-100)
}

.unselectedOption  {
  background-color: var(--white-100);
  border-radius: 8px;
  margin: 8px 0px;
  width: 100%;
}

.unselectedOption svg {
  color: var(--black-100);
  padding-left: 12px;
}

.image {
  max-height: 20rem;
  max-width: 20rem;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .image {
    max-height: 10rem;
    max-width: 10rem;
  }
}

