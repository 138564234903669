.progress {
    display: 'flex';
    background-color: var(--white-200);
    width: 84px;
    height: 8px;
    border-radius: 8px;
}

.bar {
    height: 8px;
    background: linear-gradient(to right, #FFBC28, #F23A3A);
    border-radius: 8px;
}