.externo {
  overflow-x: hidden;
}

.list {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    margin: 0;
    height: 150px;
    /* max-width: 375px; */
    width: 100%;
    margin-top: 16px;
}

.divVideo {
    display: flex;
    justify-content: center;

}

.video {
    padding: 16px 0px;
    width: 100%;
    height: auto;
    max-width: 700px;
    min-height: 320px;
    text-align: center;
}

@media (min-width: 478px) {
  .externo {
    overflow-x: hidden;
  }
  
  .list {
      overflow-x: hidden;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      height: 250px;
      width: 100%;
      min-width: 800px;
      margin-top: 16px;
  }
  
  .divVideo {
      display: flex;
      justify-content: center;
  
  }
  
  .video {
      padding: 16px 0px;
      width: 100%;
      height: auto;
      max-width: 1000px;
      min-height: 600px;
      text-align: center;
  }
}




  