.home_footer {
    position: sticky;
    left: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 16px;
    width: auto;
    height: var(--height-footer);
    bottom: -1px;
    background-color: #FFFFFF;
}

.home_footerSecundary {
    position: sticky;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: var(--height-footer);
    bottom: -1px;
    background-color: #FFFFFF;
}

.home_buttonNext {
    font-size: 16px;
    width: 160px;
    height: 48px;
    margin: 16px;
    text-transform: capitalize;
    font-weight: 700;
    color: #FFFFFF;
    background-image: linear-gradient(to right, #FFBC28, #F23A3A);
}

.home_buttonChat {
    /* padding: 16px; */
    height: 40px;
    width: 40px;

}

.home_buttonPrev {
    font-size: 16px;
    max-width: 160px;
    width: 100%;
    height: 40px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: capitalize !important;
    background-color: #A8A8A8 !important;
}

@media (min-width: 767px) {
    .home_footer {
        position: sticky;
        left: 0;
        right: 0;
        display: flex;
        justify-content: right;
        align-items: center;
        padding: 16px;
        width: auto;
        height: 81px;
        bottom: -1px;
        background-color: #FFFFFF;

    }

    .home_footerSecundary {
        position: sticky;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        height: var(--height-footer);
        bottom: -1px;
        background-color: #FFFFFF;
    }
}