:root {

  --degrade: linear-gradient(to right, #FFBC28, #F23A3A);

  --black-100: #727272;
  --black-200: #383838;
  --black-300: #272727;
  --black-400: #161616;

  --red-100: #FFB2B2;
  --red-100: #FF7878;
  --red-100: #E04444;
  --red-100: #783030;

  --blue-100: #B2FFFF;
  --blue-200: #78FFFF;
  --blue-300: #4FC1C8;
  --blue-400: #307478;

  --orange-100: #FFD4AD;
  --orange-200: #FFB978;
  --orange-300: #C8824F;
  --orange-400: #785330;

  --green-100: #ADFFB5;
  --green-200: #7BFF78;
  --green-300: #60C84F;
  --green-400: #307833;


  --white-100: #FFFFFF;
  --white-200: #EDEDED;
  --white-300: #D9D9D9;
  --white-400: #A8A8A8;

  --height-header: 70px;
  --height-footer: 71px;
}

html, body {
  background-color: white;
}

* {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
}

h1 {
  font-weight: 700;
  size: 40px;
  line-height: 48.76px;
  letter-spacing: 0.02em;
  color: var(--white-300);
}

h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.02em;
  background: linear-gradient(45deg, #FFBC28 0%, #F23A3A 62.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: var(--white-400);
}

h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: var(--white-400);
  text-transform: uppercase;
}

h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: var(--black-100);
}
