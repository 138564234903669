.container {
    width: 100%;
}

.input {
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
    padding-top: 16px;
}

.field {
    background-color: white;
}

/* .input div:nth-child(2) {
    background-color: white;
} */

/* .input div:nth-child(2)::before {
    background-color: black;
} */

.input div:focus-within {
    color: var(--black-400);
    border: black
}

.input fieldset:focus-within {
    color: var(--black-400);
    border: black
}

.background {
    background-color: var(--white-400);
}

.input label {
    color: var(--black-200);
}

.image {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 100%;
    height: auto;
}