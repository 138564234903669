.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    max-width: 180px;
    width: 100%;
    height: 40px;
    font-weight: 700;
    border-radius: 8px;
    text-transform: capitalize !important;
    color: var(--white-100);
    background: linear-gradient(50deg, #FFBC28 14.51%, #F23A3A 62.2%);
}


.btnStrong {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 160px;
    height: 40px;
    font-weight: 700;
    border-radius: 8px;
    text-transform: capitalize !important;
    color: var(--white-100);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(50deg, #FFBC28 14.51%, #F23A3A 62.2%);
}


.btnWeak {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 160px;
    height: 50px;
    font-weight: 700;
    border-radius: 8px;
    text-transform: capitalize !important;
    color: var(--white-100);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(50deg, #FFBC28 14.51%, #F23A3A 62.2%);
}

