.home_header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: sticky;
    top: 0;
    width: auto;
    height: var(--height-header);
    background-color: #FFFFFF;
    z-index: 99;
}

.home_img {
    height: 90px;
    width: 120px; 
}

.items {
    display: flex;
    align-items: center;
}   

.double {
    display: flex;
    align-items: center;
    
}

