.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    width: 100vw;
    transition: opacity 4s ease-in-out;
    background-color: white;
    z-index: 999;
    text-align: center;
    top: 0;
  }
  
  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .image {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    width: 252px;
    height: 48px;
  }

  .image.logo{
    margin-bottom: 10px;
    height: 100px;
    width: 200px;
  }
  
  .fadeIn {
    opacity: 1;
  }